//React
import React from 'react'

//Gatsby
import { graphql } from 'gatsby'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import NavSubUsecases from '../../components/nav/navSubUsecases'
import FeatureQuad from '../../components/marketing/featureQuad'
import AlternatingCallout from '../../components/marketing/alternatingCallout'

const useCaseData = [
  {
    title: 'Keeping your property safe',
    titleWeight: 'medium',
    titleColor: 'white-1',
    description:
      'Maintaining a healthy environment is critical to keeping your hotel, venue, or mode of transport open - and avoiding health closures',
    descriptionColor: 'white-1',
    image: 'usecaseTravel',
    iconShow: false,
    // iconColor: "purple",
    lineClass: 'green-1',
    bgcolor: 'yellow-1',
    bggradient: 'warm',
  },
  {
    title: 'On-site safety with mobile health assessments',
    titleWeight: 'medium',
    description:
      'Deliver automated health self-certification questions to guests or employees prior to their arrival, helping maintain a unified health standard for everyone on-site',
    image: 'usecaseTravelSolution',
    iconShow: false,
    iconColor: 'pink',
    lineClass: 'blue-5',
    // videosource: 'causemarketing',
  },
]
const featureData = {
  title: 'Reopen your properties with less risk',
  titlecolor: 'black-4',
  mode: 'trio',
  bgcolor: 'grayblue-6',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Log health responses',
          colbgcolor: 'sky-1',
          iconname: 'audit',
          description:
            'Record, log, and audit employee & guest health self-certifications in case of adverse events',
        },
        {
          title: 'Assure customers',
          colbgcolor: 'blue-1',
          iconname: 'recentactors',
          description:
            'Show current and future customers your venue is safer to visit because of your health self-assessment program',
        },
        {
          title: 'No apps or downloads',
          colbgcolor: 'dkblue-1',
          iconname: 'iphone',
          description:
            'Use an intuitive, text message workflow to screen customers for risk-based health indicators -  no apps to download or install',
        },
      ],
    },
  ],
}

const About = props => (
  <Layout>
    <Helmet
      title="Picohealth - Travel and Hospitality - Automated Mobile Health Assessments"
      meta={[
        {
          name: 'description',
          content:
            'Learn about how the Picohealth automated mobile health assessment platform helps reduce risk and drive customer confidence for your hotel, resort, venue, or mode of transport',
        },
        {
          name: 'keywords',
          content:
            'Covid, Covid-19, health, health questionnaire, covid questions, return to work, pandemic, pandemic assessment questions, worker health question, workplace health questions, workplace health, company health, company safety',
        },
      ]}
    />

    <NavSubUsecases />

    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-xl-10 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium blue-1">
            Reopen venues with confidence
          </h1>
          <p class="display-4">
            Welcome travel &amp; hospitality guests back confidently when you
            use mobile, SMS-based health screenings
          </p>
        </div>
      </div>
    </div>

    {/* <DividerMid
      class="orange-1"
      bottomclass="white-4"
      flip={false}
      lineclass="blue-1"
      lineoffset={4}
      lineflip={true}
      lineclass2="purple-1"
      lineoffset2={6}
      lineflip2={false}
      // lineclass3="yellow-1"
      // lineoffset3={10}
      // lineflip3={false}
    /> */}

    <AlternatingCallout featureData={useCaseData} />
    {/* 
    <BenefitsBoomerang
      color="white-1"
      bgcolor="blue-1"
      gradientOverlay={true}
      containerTitle="Benefits for brands"
      data={[
        {
          title: "Title 1",
          body:
            "Multiply the reach of social campaigns through compelling, mass-personalized videos.",
        },
        {
          title: "Title 2",
          body:
            "Measure the impact of personalized video with social landing pages and analytics",
        },
        {
          title: "Title 3",
          body:
            "Use a variety of creative treatments to engage users, and ensure brand-safe content with moderation",
        },
      ]}
    /> */}

    {/* SELECTED FEATURES */}
    <FeatureQuad featureData={featureData} />
    {/* END SELECTED FEATURES */}
  </Layout>
)

export default About

export const pageQuery = graphql`
  query {
    usecaseConference: file(
      relativePath: { eq: "usecase/platinumcircle-landing.png" }
    ) {
      childImageSharp {
        fixed(height: 260, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    usecaseReviews: file(
      relativePath: { eq: "usecase/protennisoutlet-landing.png" }
    ) {
      childImageSharp {
        fixed(height: 260, cropFocus: NORTHWEST, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    usecaseReviewsServices: file(
      relativePath: { eq: "usecase/jennysdoggroomers-landing.png" }
    ) {
      childImageSharp {
        fixed(height: 260, cropFocus: NORTHWEST, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    usecaseExperience: file(
      relativePath: { eq: "usecase/adventure-rally-landing.png" }
    ) {
      childImageSharp {
        fixed(height: 260, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    usecaseFestivalEvents: file(
      relativePath: { eq: "usecase/summerfestival-landing.png" }
    ) {
      childImageSharp {
        fixed(height: 260, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    usecaseSeasonal: file(relativePath: { eq: "usecase/july4-landing.png" }) {
      childImageSharp {
        fixed(height: 260, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    usecaseEmployerBrand: file(
      relativePath: { eq: "usecase/holiday-party-title.png" }
    ) {
      childImageSharp {
        fixed(height: 260, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
